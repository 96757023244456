blockquote {
  margin: 20px 0;
  padding: 10px 20px;
  border-left: solid 4px var(--light-gray);
}

blockquote > :first-child {
  margin-top: 0;
}

blockquote > :last-child {
  margin-bottom: 0;
}
